import { useContext, useEffect, useState } from 'react';
import './BlogById.sass';
import { Context } from '../..';
import { BlogList } from '../../models/BlogList';
import { Link } from 'react-router-dom';

interface Blog {
    id: string;
}

const BlogById = ({id}: Blog) => {
    const {store} = useContext(Context);
    const [blog, setBlog] = useState<BlogList>();

    useEffect(() => {
        const fetchData = async () => {
            const res = await store.getOneBlogById(id);
            setBlog(res);                                                
        }
        fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const text = document.querySelector('.blog-by-id__text');
    if (text !== null) {
        text.innerHTML = String(blog?.text).replaceAll('\n', "<br/>");
    }

    const toggleDeleteModal = () => {   
        document.querySelector('.blog-by-id__modal')?.classList.toggle('blog-by-id__modal_none');
    }

    console.log(blog?.img);
    

    return (
        <main className='blog-by-id'>
            <div className="blog-by-id__wrapper container">                            
                {store.user.admin && <i className="fa-solid fa-trash" onClick={toggleDeleteModal}></i>}          
                <h1 className="blog-by-id__title">{blog?.title}</h1>    
                <img src={"https://server.someoneinteresting.com/api/images/blog/" + blog?.img} alt="" />  
                <h3 className="blog-by-id__subtitle">{blog?.subtitle}</h3>
                <p className="blog-by-id__text"></p>
                <span>{String(blog?.date).slice(0,10).replaceAll('-', '.')}</span>
            </div>
            <div className="blog-by-id__modal blog-by-id__modal_none">
                <div>Are you sure?</div>
                <div className="blog-by-id__btns">
                    <Link to='/blog' onClick={() => store.deleteBlog(id)}>
                        <button >Yes</button>
                    </Link>
                    <button onClick={toggleDeleteModal}>No</button>
                </div>               
            </div>
        </main>
    )
}

export default BlogById;