import Carousel from 'react-bootstrap/Carousel';
import './Carousel.sass';

function SimpleCarousel() {
  return (
    <Carousel controls={false} indicators={false}>
      <Carousel.Item interval={3000}>
        <img
          className="d-block w-100"
          src="https://server.someoneinteresting.com/api/images/blog/ae9befd1-7d18-463d-adcf-31532ac2ca62.png"
          alt="First slide"
        />
      </Carousel.Item>
      <Carousel.Item interval={3000}>
        <img
          className="d-block w-100"
          src="https://server.someoneinteresting.com/api/images/blog/d7d99a68-558b-4e05-adb7-1f0feed4d5d6.png"
          alt="Second slide"
        />
      </Carousel.Item>
      <Carousel.Item interval={3000}>
        <img
          className="d-block w-100"
          src="https://server.someoneinteresting.com/api/images/blog/72f305e4-1258-40f3-8a8d-c135b0e7e2f4.png"
          alt="Third slide"
        />
      </Carousel.Item>
    </Carousel>
  );
}

export default SimpleCarousel;