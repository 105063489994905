import { Link } from 'react-router-dom';
import './Blog.sass';
import { useState } from 'react';
import { BlogList } from '../../models/BlogList';
import Search from '../../components/search/Search';

interface blogList {
    blogList: BlogList[] | undefined
}

const Blog = ({blogList}: blogList) => {
    const [type, setType] = useState<string>('');
    const [country, setCountry] = useState<string>('');
    const [reversed, setReversed] = useState<boolean>(false);

    const getType = (type: string) => {
        setType(type);
    }

    const getCountry = (country: string) => {
        setCountry(country);
    }

    const getReversed = (reversed: boolean) => {
        setReversed(reversed);
    }

    let filtered:any = [];

    if (country) {
        filtered = blogList?.filter((element) => element.type.includes(type) && 
            element.country ? element.country.includes(country) : false
        );
    } else {
        filtered = blogList?.filter((element) => element.type.includes(type));        
    }
    if (filtered) {
        !reversed && filtered.reverse();
    }

    return (
        <main className='blog'>
            <Search isBlog={true} isReversed={getReversed} setType={getType} setCountry={getCountry} setState={getType}></Search>
            <div className="blog__wrapper container">
                <div className="blog__list">
                    {filtered?.map((element: any) => (
                        <Link key={element._id} to={'/blog/' + element._id} className="blog__item" onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>
                            <img src={"https://server.someoneinteresting.com/api/images/blog/" + element.img} alt="Country" />  
                            <div className="blog__type">{element.type}</div>
                            <div className="blog__content">
                                <h3 className="blog__title">{element.title}</h3>
                                <p className="blog__descr">{element.text.slice(0,230)}...</p>
                                <span>{String(element.date).slice(0,10).replaceAll('-', '.')}</span>
                            </div>            
                        </Link>
                    ))}           
                    {!filtered && <h4>No matches found</h4>} 
                    {filtered && filtered.length === 0 && <h4>No matches found</h4>}         
                </div>               
            </div>
        </main>
    )
}

export default Blog;