import { useContext, useEffect, useState } from 'react';
import './AdminPanel.sass';
import { Context } from '../..';
import Login from '../login/Login';

const AdminPanel = () => {
    const [type, setType] = useState<string>('Country');
    const [countries, setCountries] = useState([]);
    const [currentCountry, setCurrentCountry] = useState<string>('');
    const [title, setTitle] = useState<string>('');
    const [subtitle, setSubtitle] = useState<string>('');
    const [text, setText] = useState<string>('');
    const [img, setImg] = useState<any>();

    const {store} = useContext(Context);

    useEffect(() => {
        const fetchData = async () => {
            fetch('https://countriesnow.space/api/v0.1/countries/states')
                .then((res) => res.json())
                .then((res) => setCountries(res.data));
        }
        fetchData();
    }, [])

    const countriesNames:any = [];
    countries.forEach((element: any) => {
        countriesNames.push(element.name);
    })

    const handleSetImg = (event: any) => {
        setImg(event.target.files[0]);
    }

    const addBlog = (event: any) => {
        if (currentCountry || title || subtitle || text || img) {
            event.preventDefault();

            const formData = new FormData();
            formData.append('img', img);
            formData.append('type', type);
            formData.append('country', currentCountry);
            formData.append('title', title);
            formData.append('subtitle', subtitle);
            formData.append('text', text);

            store.addBlog(formData);

            alert('Post added');
        } else {
            alert('Fill in all the fields')
        }
    }

    return (
        <main className='admin-panel'>
            <div className="admin-panel__wrapper container">
                {store.user.admin ? (
                    <form className="admin-panel__add-form">
                        <div>
                            <label htmlFor="type">Choose the type</label>
                            <select name="type" id="type" value={type} onChange={(event) => setType(event.target.value)}>
                                <option value="Country">Country</option>
                                <option value="Tour">Tour</option>
                            </select>
                        </div>
                        <div>
                            <label htmlFor="country">Country</label>
                            <select name="country" id="country" onChange={e => setCurrentCountry(e.target.value)}>
                                <option value="">...</option>
                                {countriesNames.map((element: string, index: number) => (                 
                                    <option key={index} value={element}>{element}</option>
                                ))}
                            </select>
                        </div> 
                        <div>
                            <label htmlFor="img" >Upload your photo</label>
                            <input type="file" name="img" id="img" accept='image/*' onChange={(event) => handleSetImg(event)}/>
                        </div>
                        <div>
                            <label htmlFor="title">Input title</label>
                            <input type="text" name="title" id="title" value={title} onChange={(event) => setTitle(event.target.value)}/>
                        </div>
                        <div>
                            <label htmlFor="subtitle">Input subtitle</label>
                            <input type="text" name="subtitle" id="subtitle" value={subtitle} onChange={(event) => setSubtitle(event.target.value)}/>
                        </div>
                        <div>
                            <label htmlFor="text">Input text</label>
                            <textarea name="text" id="text" value={text} onChange={(event) => setText(event.target.value)}/>
                        </div>     
                        <input type="submit" value="ADD NEW POST" onClick={(event) => addBlog(event)}/> 
                    </form> 
                ) : (
                    <Login type={'admin'}></Login>
                )}   
            </div>
        </main>
    )
}

export default AdminPanel;