import $api from "../http";
export default class AddService {
    static async editGuide(firsName: string, lastName: string, languages: string, text: string, country: string, state: string, city: string) {
        return $api.post('/edit-guide', {firsName, lastName, languages, text, country, state, city});
    }

    static async getAllGuides() {
        return $api.get('/get-all-guides');
    }

    static async getGuideImg(id: string){
        return $api.post('/get-guide-img', {id});
    }
    static async getGuidesImages(){
        return $api.get('/get-guides-images');
    }

    static async addGuideImage(formData: FormData) {
        return $api.post('/add-guide-image', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    }
    
    static async addBlog(formData: FormData) {
        return $api.post('/add-blog', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    }
    
    static async editBlog(id: string, country: string, type: string, img: string, title: string, subtitle: string, text: string) {
        return $api.post('/edit-blog', {id, country, type, img, title, subtitle, text});
    }

    static async deleteBlog(id: string) {
        return $api.post('/delete-blog', {id});
    }

    static async getAllBlog() {
        return $api.get('/get-all-blog');
    }

    static async getOneBlog(id: string) {
        return $api.post('/get-one-blog', {id});
    }

    static async getBlogImg(id: string){
        return $api.post('/get-blog-img', {id});
    }
    
    static async getBlogsImages(){
        return $api.get('/get-blogs-images');
    }
}