import { useContext, useEffect, useState } from 'react';
import './Guides.sass';
import { Context } from '../..';
import { GuidesList } from '../../models/GuidesList';
import Search from '../../components/search/Search';

const Guides = () => {

    let [guides, setGuides] = useState<Array<GuidesList>>();
    const {store} = useContext(Context);

    useEffect(() => {
        const fetchData = async () => {
            const res = await store.getAllGuides();
            setGuides(res);        
        }
        fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const [country, setCountry] = useState<string>('');
    const [state, setState] = useState<string>('');

    const getCountry = (country: string) => {
        setCountry(country);
    }

    const getState = (state: string) => {
        setState(state);
    }
    guides = guides?.filter(element => element.updated);
    const filtered = guides?.filter((element) => element.country.includes(country) && 
        element.state.includes(state)
    );

    const toggleModal = (id: string) => {   
        document.querySelector(`.guide-num_${id}`)?.classList.toggle('guides__modal-none');
    }
    
    return (
        <main className='guides'>
            <Search isBlog={false} isReversed setType={getCountry} setCountry={getCountry} setState={getState}></Search>
            <div className="guides__wrapper container">
                <div className="guides__list">
                    {filtered?.map((element) => element.updated ? (
                        <div key={String(element._id)} className="guides__item">
                            <div className={"guides__modal guides__modal-none guide-num_" + element._id}>
                                <div>You can contact the guide through this mail</div>
                                <span>{element.email}</span>
                                <i className="fa-solid fa-xmark" onClick={() => toggleModal(String(element._id))}></i>
                            </div>
                            <div className="guides__preview">
                                <img src={"https://server.someoneinteresting.com/api/images/guides/" + element.img} alt="Guide" />
                                <button disabled={store.user.type !== 'user'} onClick={() => toggleModal(String(element._id))}>Link with guide</button>                            
                            </div>
                            <div className="guides__about">
                                <p className="guides__name">{element.firstName} {element.lastName}</p>
                                <p className="guides__country">{element.country}, {element.state}, {element.city}</p>
                                <p><span>Languages:&nbsp;</span>{element.languages}</p>
                                <p><span>Biography:&nbsp;</span>{element.text}</p>
                            </div>                         
                        </div>
                    ) : (<></>))}
                    {!filtered && <h4>No matches found</h4>} 
                    {filtered && filtered.length === 0 && <h4>No matches found</h4>}    
                </div>               
            </div>
        </main>
    )
    
}

export default Guides;