import Header from './components/header/Header';
import Home from './pages/home/Home';
import Blog from './pages/blog/Blog';
import Guides from './pages/guides/Guides';
import Login from './pages/login/Login';
import { Routes, Route } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useContext, useEffect, useState} from 'react';
import { Context } from '.';
import BlogById from './pages/blogById/BlogById';
import AdminPanel from './pages/adminPanel/AdminPanel';
import GuideById from './pages/guideById/GuideById';
import { BlogList } from './models/BlogList';
import Footer from './components/footer/Footer';
import TermsAndConditions from './pages/termsAndConditions/TermsAndConditions';
import PrivacyPolicy from './pages/privacyPolicy/PrivacyPolicy';
const App = () => {

    const {store} = useContext(Context);
    const [blogList, setBlogList] = useState<Array<BlogList>>();

    useEffect(() => {
		if (localStorage.getItem('token')) {
            if (localStorage.getItem('type') === 'user') {
                store.checkAuth();  
            }           
            if (localStorage.getItem('type') === 'guide') {
                store.checkAuthForGuide();
            }
            if (localStorage.getItem('type') === 'admin') {
                store.checkAuthForAdmin();  
            }
		}
        const fetchData = async () => {
            const res = await store.getAllBlog();
            setBlogList(res);
        }
        fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
    
    return (
        <div className='app'>
            <Header isAuth={store.isAuth}></Header>
            <div className="app__sections">
                <Routes>
                    <Route path='/' element={<Home blogList={blogList}/>}/>
                    <Route path='*' element={<Home blogList={blogList}/>}/>
                    <Route path='/admin-panel' element={<AdminPanel/>}/>
                    <Route path='/blog' element={<Blog blogList={blogList}/>}/>
                    {blogList?.map((item) => (
                        <Route key={item._id} path={'/blog/' + item._id} element={<BlogById id={item._id}/>}/>
                    ))}
                    <Route path='/guides' element={<Guides/>}/>
                    <Route path='/guides/profile' element={<GuideById/>}/>               
                    <Route path='/login-traveler' element={<Login type={'user'}/>}/>
                    <Route path='/login-guide' element={<Login type={'guide'}/>}/> 
                    <Route path='/terms-and-conditions' element={<TermsAndConditions/>}/> 
                    <Route path='/privacy-policy' element={<PrivacyPolicy/>}/>                    
                </Routes>
            </div>
            <Footer></Footer>
        </div>
    )
}

export default observer(App);