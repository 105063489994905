import { useEffect, useState, useContext } from 'react';
import './GuideById.sass';
import { Context } from '../..';

const GuideById = () => {
    const [currentCountry, setCurrentCountry] = useState<string>('');
    const [currentState, setCurrentState] = useState<string>('');
    const [currentCity, setCurrentCity] = useState<string>('');
    const [countries, setCountries] = useState([]);
    const [cities, setCities] = useState([]);
    const {store} = useContext(Context);
    const [firsName, setFirstName] = useState<string>('');
    const [lastName, setLastName] = useState<string>('');
    const [languages, setLanguages] = useState<string>('');
    const [text, setText] = useState<string>('');
    const [validText, setValidText] = useState<boolean>(false);
    const [validForm, setValidForm] = useState<boolean>(false);
    const [img, setImg] = useState<string>('');
    
    useEffect(() => {
        const fetchData = async () => {          
            await store.getAllGuides().then((res) => {
                res?.forEach((element: any) => {
                    if (element._id === store.user.id) {
                        setImg(element.img);
                    }
                })
            });
        }
        fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        const fetchData = async () => {          
            fetch('https://countriesnow.space/api/v0.1/countries/states')
                .then((res) => res.json())
                .then((res) => setCountries(res.data));
        }
        fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        const fetchData = async () => {
            if (currentState !== '') {       
             
                fetch('https://countriesnow.space/api/v0.1/countries/states')
                    .then((res) => res.json())
                    .then((res) => setCountries(res.data));
                fetch('https://countriesnow.space/api/v0.1/countries/state/cities', {
                    method: 'POST',
                    headers: {
                        'Content-Type' : 'application/json'
                    },
                    body: JSON.stringify({
                        "country" : currentCountry,
                        "state" : currentState
                    })                           
                })
                    .then((res) => res.json())
                    .then((res) => setCities(res.data));
            }    
        }    
        fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentState])

    const countriesNames:any = [];
    countries.forEach((element: any) => {
        countriesNames.push(element.name);
    })

    const getStateForCountry = (country: any):Array<string> => {
        const arr:any = [];
        countries.forEach((element: any) => {           
            if (element.name === country) {
                element.states.forEach((element: any) => {
                    arr.push(element.name);
                });
                return arr;
            }
        })
        return arr;
    }

    useEffect(() => {
        if (firsName === '' || lastName === '' || languages === '' || text === '' || currentCountry === '' || currentState === '') {
            setValidForm(false);
        } else {
            setValidForm(true);
            if (text.length >= 220) {
                setValidText(true);
            } else {
                setValidText(false);
            }
        }
    }, [firsName, lastName, languages, text, currentCountry, currentState, currentCity])

    const updateGuideInfo = (event: any) => {
        event.preventDefault();
        if (validForm && validText) {
            store.editGuide(firsName, lastName, languages, text, currentCountry, currentState, currentCity);
            alert('Your guide card has been added and modified');     
        } else {
            if(!validForm) {
                alert('Fill in all the fields');
            } else {
                if(!validText) {
                    alert('Too little text')
                } 
            }
        }
    }

    const sendImg = (event: any)  => {     
        const formData = new FormData();
        formData.append('img', event.target.files[0])
        formData.append('_id', store.user.id)
        store.addGuideImage(formData);            
    }

    return (
        <main className='guide-by-id'>
            <div className="guide-by-id__wrapper container">
                <h1 className="guide-by-id__title">Create your guide card</h1>
                <form id='form' className="guide-by-id__form" action="">                  
                    <div className="guide-by-id__about">
                        <div className='guide-by-id__img'>
                            <img src={img ? `https://server.someoneinteresting.com/api/images/guides/${img}` : 'https://png.pngitem.com/pimgs/s/111-1114675_user-login-person-man-enter-person-login-icon.png'} alt="" />
                            <label className='guide-by-id__label' htmlFor="img" >Upload your photo</label>
                            <input type="file" name="img" id="img" accept='image/*' onChange={(event) => sendImg(event)}/>
                        </div>
                        <div className="guide-by-id__text">
                            <label htmlFor="GuideText">Write a little about yourself</label>
                            <textarea name="GuideText" id="GuideText" form="form"cols={30} rows={10} maxLength={320} value={text} onChange={(event) => setText(event.target.value)}>    
                            </textarea>
                        </div>
                    </div>
                    <div className="guide-by-id__about">
                        <div className='guide-by-id__info'>
                            <div>
                                <label htmlFor="firstName">First Name</label>
                                <input id='firstName' type="text" value={firsName} placeholder='First Name' onChange={(event) => setFirstName(event.target.value)}/>
                            </div>
                            <div>
                                <label htmlFor="lastName">Last Name</label>
                                <input id='lastName' type="text" value={lastName} placeholder='Last Name' onChange={(event) => setLastName(event.target.value)}/>
                            </div>
                            <div>
                                <label htmlFor="languages">Languages</label>
                                <input id='languages' type="text" value={languages} placeholder='Languages you speak' onChange={(event) => setLanguages(event.target.value)}/>
                            </div>                    
                        </div>
                        <div className='guide-by-id__location'>
                            <div>
                                <label htmlFor="country">Country</label>
                                <select name="country" id="country" onChange={e => setCurrentCountry(e.target.value)}>
                                    <option value="">...</option>
                                    {countriesNames.map((element: string, index: number) => (                 
                                        <option key={index} value={element}>{element}</option>
                                    ))}
                                </select>
                            </div>                       
                            <div>
                                <label htmlFor="state">State</label>
                                <select name="state" id="state" onChange={e => setCurrentState(e.target.value)}>
                                    <option value="">...</option>
                                    {getStateForCountry(currentCountry).map((element: string, index: number) => (                 
                                        <option key={index} value={element}>{element}</option>
                                    ))}
                                </select>
                            </div>
                            <div>
                                <label htmlFor="city">City</label>
                                <select name="city" id="city" onChange={e => setCurrentCity(e.target.value)}>
                                    <option value="">...</option>
                                    {typeof cities === 'undefined' ? (<div></div>) : cities.map((element: string, index: number) => (                 
                                        <option key={index} value={element}>{element}</option>
                                    ))}
                                </select>
                            </div>                       
                        </div>                          
                    </div>    
                    <input form='form' type="submit" value='SUBMIT' onClick={(event) => updateGuideInfo(event)}/>                       
                </form>               
            </div>
        </main>
    )
}

export default GuideById;