import './TermsAndConditions.sass'

const TermsAndConditions = () => {
    return (
        <main className='termsAndConditions container'>
            <h3>Terms and Conditions</h3>
            <p>
                Please read these “Terms and Conditions” carefully as they contain important information about your legal rights and obligations.<br/>
                We operate a marketplace platform that connects travelers and tour guides. Our platform allows the tour guides to market their tours and process payments and travelers to find and book tours and post reviews. We do not publish, create, sell, control, offer, deliver, or supply any tour. When the travelers make bookings, they are entering into a contract directly with the guides. In no event shall FeelingTraveling be deemed a party to any agreement whereby a traveler books a tour from a guide. The guides, and not FeelingTraveling, are responsible for the tours they publish and for their guiding services.<br/>
                IMPORTANT: PLEASE REVIEW THE LITIGATION CLASS ACTION WAIVER AND ARBITRATION PROVISIONS CONTAINED HEREIN, AS THEY REQUIRE THE PARTIES TO RESOLVE DISPUTES ON AN INDIVIDUAL BASIS AND TO SUBMIT DISPUTES TO FINAL AND BINDING ARBITRATION. IF YOU DO NOT AGREE, DO NOT ACCESS OR USE THE SITE AND/OR PARTICIPATE IN A TOUR.<br/>
                <br/>
                <b>1. Introduction.</b><br/>
                <br/>
                (b) a person or entity (“You”) that enters and uses the FeelingTraveling’ marketplace platform accessible via the www.FeelingTraveling.com website and subdomains (the “Site”) to search for and/or book sightseeing tours and activities and other related services (the “Tours”), and/or participates in tours booked through FeelingTraveling and/or the Site.<br/>
                <br/>
                <b>2. Acceptance of These Terms and Conditions.</b>
                <br/>
                <br/>
                You hereby represent and warrant that:<br/>
                (a) You have read, understand, and agree to comply with and be bound by these Terms and Conditions;<br/>
                (b) You are an individual at least 18 years old or a duly organized, validly existing business, organization or other legal entity in good standing under the laws of the country in which You are established;<br/>
                (c) You are able to enter into legally binding contracts;<br/>
                (d) You are not forbidden by FeelingTraveling to access and/or use the Site; and<br/>
                (e) If you take additional participants to your Tour, You agree and warrant that the participants, or their legal guardians, have read and agreed to these Terms and Conditions, including, but not limited to, the Acceptance or Risk and Waiver Liability, Disclaimer, Limitation of Liability and Your Liability and Indemnification sections, which apply to all participants, as if the term “You” was a reference to the participants.<br/>
                If You do not agree to be bound by these Terms and Conditions, You may not access and/or use the Site, and should not participate in a Tour.<br/>
                These Terms and Conditions should be read together with the Cancellation Policy and Privacy Policy, as they are incorporated by reference into these Terms and Conditions.<br/>
                <br/>
                <b>3. Scope of FeelingTraveling Services.</b>
                <br/>
                <br/>
                We operate the Site, which is an online marketplace platform which allows the Guides to upload and publish their Tours, and You to search and book Tours, post reviews and process payments.<br/>
                You may search for Tours and Guides using a variety of filters. Once You decide on a specific Tour, You may connect and conclude contracts directly with the Guides and through the Site.<br/>
                We are constantly updating our Site and its functionalities. Therefore, We may modify and/or delete any functionality on the Site and/or introduce new ones from time to time.<br/>
                <br/>
                <b>4. User Account.</b>
                <br/>
                <br/>
                You may search for Tours and Guides through the Site without prior registration. In order to book Tours on the Site, You may be required to register to open an account and complete your profile with your personal details (Traveler’s Account). You shall not apply to open more than one Traveler’s Account on the Site.<br/>
                The opening of a Traveler’s Account is at the sole discretion of FeelingTraveling. We reserve the right to refuse to open a Traveler’s Account and/or cancel a Traveler’s Account without notice and liability, at our sole discretion, for any reason or for no reason.<br/>
                You must provide accurate, current and complete information and keep the information on your Traveler’s Account up-to-date at all times. We will only collect and use personal information from You in accordance with our Privacy Policy.<br/>
                You are solely and exclusively responsible for any claims relating to any inaccurate, untimely or incomplete information provided by You.<br/>
                You are responsible for any activity on your Traveler’s Account, and shall keep your password confidential. If You are aware that any person has entered your Traveler’s Account without your permission, You shall inform FeelingTraveling as soon as possible.<br/>
                <br/>
                <b>5. Booking.</b>
                <br/>
                <br/>
                The Site lists many Tours uploaded by different Guides, each Tour containing a description of the characteristics and items included, such as description and itinerary (“Tour Terms”). You may send a message to the Guide with any inquiry about a certain Tour or to request a customized Tour. You may also choose to make a booking request to a Guide for a particular Tour by clicking the “Book Now” button, or any other button as may be modified. All Tours are invitations to You to make an offer to a Guide, except for the last-minute bookings (“Instant Bookings”) which may be booked automatically without a Guide’s acceptance. Once the Guide accepts and confirms your booking request, there is a binding contract between You and the Guide. The Guides are free to accept or reject your booking requests.<br/>
                You shall review the Tour Terms before making a booking request to a Guide. Once your booking request is accepted by the Guide, You will be bound by the Tour Terms. These Terms and Conditions will prevail over the Tour Terms with respect to your relationship with FeelingTraveling.<br/>
                You understand and agree that due to the ongoing disruptions related to the Covid-19 pandemic the Tours itineraries may be subject to changes that are out of the control of the Guide. You agree that only changes that substantially impact the Tour itinerary will entitle You to cancel such Tour without cost.<br/>
                <br/>
                <b>6. Live Virtual Tours.</b>
                <br/>
                <br/>
                If You book a live virtual tour on our Site You agree with the following:<br/>
                (a) hand-picked staff of FeelingTraveling may randomly access any virtual tour session for technical quality purposes;<br/>
                (b) the Guide owns or otherwise controls all of the rights to the live virtual tour and grants You a non-exclusive, worldwide, royalty-free, irrevocable, perpetual license to access and use the live virtual tour for personal and non commercial purposes only; and<br/>
                (c) FeelingTraveling is not responsible for any damages or technical malfunction of any hardware, network, or systems related to, or resulting from, the participation in a live virtual tour, including any injury or damage to any participant and to any person’s computer.<br/>
                <br/>
                <b>7. Payment.</b>
                <br/>
                <br/>
                All the Guides set up their own Tour prices, which fully compensate the Guides for their services. The Guides may change the Tour prices without prior notice until a booking has been made. Tour Prices include all taxes.<br/>
                You will ensure that any and all payments to the Guides are processed through the Site, or such other means as FeelingTraveling may determine.<br/>
                You will make payment of bookings by credit card or other form of payment available through the Site. We do not store credit card information. FeelingTraveling uses a third party service to process your payment information for all transactions made through FeelingTraveling. You authorize FeelingTraveling and its payment service partners to process your payments. These payment processing services are governed by the terms and conditions of those third party services and will apply to the payments You make through their system.<br/>
                Each Tour is displayed in its default currency. You may choose to pay for a Tour in other currencies available on the Site. You may select another currency when You search for Tours or make a booking request. Once You book a Tour, the Tour Price is fixed in such currency, even if exchange rates fluctuate between the time You book and the time You take your Tour. The exchange rates that the Site uses to convert Tour prices are set based on currency information services companies, such as Oanda.com, plus a FeelingTraveling commission. Your bank or credit card company may apply different commissions and charges when selecting a currency other than your local currency.<br/>
                <br/>
                <b>8. Your Reviews.</b>
                <br/>
                <br/>
                You may have access to post Guides’ ratings and reviews (“Reviews”) and the Guides may respond to such Reviews. By uploading, posting or otherwise making available your Reviews on or through the Site, You:<br/>
                (a) Grant to Us a non-exclusive, worldwide, royalty-free, irrevocable, perpetual (or for the term of the protection), sub-licensable and transferable license to your Reviews to access, use, reproduce, display, perform, adapt, create derivative works, modify, distribute, and promote your Reviews in any form, anywhere, in connection with FeelingTraveling’ business, without your prior approval and/or notification. This license shall survive termination of your Traveler’s Account on the Site.<br/>
                (b) Warrant and represent that You own or otherwise control all of the rights to your Reviews and that use of your Reviews by FeelingTraveling does not violate the rights of any third party;<br/>
                (c) In the event that your Reviews provided by You to FeelingTraveling violates the rights of a third party, You agree to indemnify FeelingTraveling against any losses and liabilities that may result from the action of such third party against FeelingTraveling;<br/>
                (d) Understand that other users of the Site will access your Reviews.<br/>
                You will not upload, publish, and/or send any Review that: (i) is fraudulent, false, or misleading; (ii) is defamatory, obscene, vulgar or offensive; (iii) promotes discrimination, bigotry, racism, hatred, harassment or harm against any Guide and/or user; (iv) promotes illegal or harmful activities, or (v) includes contact details.<br/>
                We reserve the right to remove or modify, without prior notice, any Reviews alleged to infringe any intellectual property rights, or that violate these Terms and Conditions, and/or unpublish any Tours containing such Reviews.<br/>
                Reviews reflect the opinions of the users of the Site and do not reflect the opinion of FeelingTraveling. FeelingTraveling may verify Reviews for accuracy but does not warrant they are correct or accurate. FeelingTraveling shall have no liability for Reviews or for any claims resulting from such Reviews.<br/>
                <br/>
                <b>9. Cancellation Policy.</b>
                <br/>
                <br/>
                You agree with the terms of the Cancellation Policy and understand that any cancellation by You or by a Guide, and any related refund, will be processed in accordance with the Cancellation Policy in force at the time of your booking.<br/>
                FeelingTraveling may cancel any booking, in its sole discretion, if We believe in good faith that such cancellation is necessary to prevent or reduce any damages to any user of the Site and/or to FeelingTraveling, or if the Guide’s Account is canceled or suspended. We will reimburse and/or refund You and/or the Guide in accordance with the reasons for cancellation and with the Cancellation Policy. We will share the contact details between You and the Guide if the Guide’s Account is canceled and You may choose to continue with your booking outside our Site.<br/>
                Refunds will be sent back to the original payment method used in making the booking. Depending on the bank's processing time, it can take 10 business days for the refunds to reflect in your bank account or card balance.<br/>
                FeelingTraveling and the Guides are not responsible for any incidental expenses You and/or any other participant may have directly or indirectly incurred as a result of the booking of a Tour. These expenses include, but are not limited to, the cost of visas, accommodation, vehicle rental, vaccinations, flights and/or loss of enjoyment.<br/>
                <br/>
                <b>10. “No One Left Behind” Shore Excursion Policy.</b>
                <br/>
                <br/>
                Our Site allows You to search and book shore excursion Tours, which are identified and marked as a shore excursion tour type on the Site (“Shore Excursions”). In the unlikely event that You miss your ship after a Shore Excursion, FeelingTraveling will arrange overnight accommodation and transportation to the next feasible port-of-call.<br/>
                FeelingTraveling will not cover overnight accommodation and transportation to the next feasible port-of-call if You miss the ship due to preventable reasons within your control, for example, if You deviate from the agreed-upon itinerary, become ill, get arrested, or provided wrong ship departure information.<br/>
                <br/>
                You shall not make any arrangements for accommodation and/or transportation on your own. You will contact FeelingTraveling to arrange your accommodation and transportation.<br/>
                <br/>
                <b>11. Rules of the Site.</b>
                <br/>
                <br/>
                You agree to the following provisions:<br/>
                (a) Use of the Site. The Site shall be used for the purposes indicated herein and not for any illegal or unauthorized purposes. You may not use the Site to recruit, solicit, or contact Guides for business offers. Also, You may not use the Site to engage in harassing, stalking or any other inappropriate conducts.<br/>
                (b) Contact Details. You agree not to give any personal contact details including full name, phone number, email, and/or social networks to the Guides before they accept a booking request.<br/>
                (c) Communication with the Guides. You agree that all communications with the Guides and prior to the Tour will occur through the Site. FeelingTraveling also handles customer service through telephone and email. FeelingTraveling reserves the right to monitor communications between You and the Guides and review and edit those communications in case they do not comply with these Terms and Conditions (like containing contact details or including offensive language). You are solely responsible for, and shall exercise caution and judgment, in using the Site and disclosing personal information to the Guides.<br/>
                (d) Communication with Us. You agree that FeelingTraveling and the Guides may contact You via email, text messages, calls, and automated calls, to the phone number and email addresses You provide in your Traveler’s Account or otherwise provided to Us, to communicate to You, including but not limited to, any updates, changes, and/or important information related to any bookings.<br/>
                (e) Guides’ Information. You agree that You will not use the Guide’s information for any purpose other than to participate in a Tour or in connection to other services contracted through the Site.<br/>
                (f) Customer Disputes. You agree to work in good faith with FeelingTraveling to address any customer disputes or complaints. If there are any issues that cannot be resolved directly with the Guide, You may contact FeelingTraveling and we will attempt to help resolve the issue. We do not control the performance of the Guides nor their services and We do not guarantee to be able to resolve any given dispute.<br/>
                (g) Purchase of Goods During a Tour. You should exercise caution if you purchase any goods during a Tour. FeelingTraveling does not design any Tour, is not aware of any goods available for purchase during a Tour and does not make any claims about the quality, source or other provenance of any goods which may be available for purchase during a Tour.<br/>
                (h) Unauthorized Access to the Site: You will not use the Site and/or participate in a Tour in any manner that could damage, disable, overburden or impair the functioning of the Site, use data mining or any other method to extract data, apply reverse engineering, or access areas of the Site which You are not authorized to access.<br/>
                (i) Surveys. You agree that by submitting any ideas, suggestions, and/or opinions (“Opinions”) to Us through our surveys and/or any other alternative means You represent and warrant that you have all rights necessary to submit your Opinions and You hereby grant to FeelingTraveling a non-exclusive, worldwide, royalty-free, irrevocable, perpetual (or for the term of the protection), sub-licensable and transferable license to access, use, reproduce, display, perform, adapt, and distribute your Opinion in any form, anywhere, in connection with FeelingTraveling’ business, without your prior approval and/or notification. This license shall survive termination of your Traveler’s Account on the Site.<br/>
                (j) Links to other websites. The Site may contain links to third-party websites which are subject to different terms and conditions. We are not liable for, nor endorse, the content and/or services available through those websites. We recommend that You read and get familiarized with the terms and conditions of such websites.<br/>
                <br/>
                <b>12. Independent Contractor Relationship.</b>
                <br/>
                <br/>
                You understand, acknowledge and agree that all the Guides are independent contractors and not employees, agents, joint venturers or partners of FeelingTraveling, and that the Guides act exclusively on their behalf and for their own benefit, and not on behalf, or for the benefit, of FeelingTraveling.<br/>
                You hereby understand and expressly agree that FeelingTraveling does not direct or control the Guides generally or in their performance during the Tours, including, but not limited to, quality, timing, legality or any other aspect whatsoever of the services actually delivered by the Guides, nor the integrity, responsibility or any of the actions whatsoever of the Guides. All Tours are uploaded and published by the Guides without any prior control and/or verification from FeelingTraveling.<br/>
                The Guides, and not FeelingTraveling, are responsible for determining, understanding and complying with all laws, rules and regulations that apply to their activity and to the Tours provided to You.<br/>
                <br/>
                <b>13. Acceptance of Risk and Waiver of Liability.</b>
                <br/>
                <br/>
                We urge You to obtain suitable medical insurance prior to taking a Tour.<br/>
                You acknowledge that all travel involves an element of risk. Also, some Tours may be adventurous in nature and may involve a significant amount of personal risk. We encourage You to ask the Guides for information and fully educate yourself about any risks, skills and level of fitness inherent to the Tour.<br/>
                Also, We strongly encourage You to search for information about your destination to determine the level of risk when traveling to certain areas. We do not make any representation and/or warranty about any risk associated with any travel destination and We are not liable for any damage or loss that may arise out of, or in connection with, any such destination.<br/>
                You hereby assume all such risk and You, your estate, your family, heirs and assigns hereby agree to release and hold harmless FeelingTraveling, its parent, subsidiaries, affiliates, directors, and employees (“FeelingTraveling Group”) from all liabilities and claims that arise from damages of every kind and nature, known and unknown, both pecuniary and non-pecuniary, including but not limited to, illness, bodily injury, disability and death to You and/or any other Tour participant, that may arise out of, or in connection with, the participation in a Tour.<br/>
                If you take a minor to a Tour, You agree to be legally authorized to act on behalf of the minor. Also, You are solely liable for the safekeeping and supervision of the minor at all times during the Tour.<br/>
                <br/>
                <b>14. Disclaimer.</b>
                <br/>
                <br/>
                THE FeelingTraveling GROUP DOES NOT PUBLISH, CREATE, SELL, CONTROL, OFFER, DELIVER, OR SUPPLY ANY GUIDED TOUR. THE GUIDES ARE NOT INDEPENDENT CONTRACTORS, AGENTS NOR EMPLOYEES OF THE FeelingTraveling GROUP. THE FeelingTraveling GROUP DOES NOT TAKE ANY RESPONSIBILITY AND IS NOT LIABLE FOR, ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, EXEMPLARY, CONSEQUENTIAL OR ANY OTHER DAMAGES OR EXPENSES, ARISING OUT OF, OR IN CONNECTION WITH, WITHOUT LIMITATION, (I) THE DELIVERY OF ANY TOUR, (II) THE PERFORMANCE OF THE GUIDE OR FAILURE TO PERFORM THE TOUR AND/OR THE GUIDE’S SERVICES, (III) ANY TRANSPORTATION SERVICE ASSOCIATED TO ANY TOUR, (IV) ANY OTHER SERVICE OFFERED OR DELIVERED BY THE GUIDE, (V) THE GUIDE’S ACTS OR OMISSIONS, REPRESENTATIONS, WARRANTIES, NEGLIGENCE, GROSS NEGLIGENCE OR WILFUL MISCONDUCT AND (IV) PERSONAL, BODILY INJURY, DEATH, PROPERTY DAMAGE TO YOU, THE GUIDE OR ANY OTHER PARTY. THE FeelingTraveling GROUP’S RESPONSIBILITY IS LIMITED TO LISTING AND MAKING AVAILABLE THE TOURS AND OTHER ANCILLARY SERVICES THROUGH THE SITE.<br/>
                ALTHOUGH WE PERFORM CERTAIN LIMITED SCREENINGS ON THE GUIDES, WE DO NOT GUARANTEE THE EFFICACY AND/OR ACCURACY OF SUCH PROCESS AND, THEREFORE, WE DO NOT GUARANTEE THE RELIABILITY OF THE GUIDES. ALSO, WE MAY NOT CONDUCT IDENTITY VERIFICATIONS AND WE ARE NOT RESPONSIBLE FOR THE IDENTITY VERIFICATION OF ANY USERS OF THE SITE, INCLUDING THE GUIDES.<br/>
                DUE TO THE NATURE OF FeelingTraveling SERVICES, FeelingTraveling DOES NOT GUARANTEE THE CONTINUOUS AND UNINTERRUPTED AVAILABILITY AND ACCESSIBILITY OF THE SITE. THE SITE IS PROVIDED ‘AS IS’ AND ‘AS AVAILABLE’, WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESS OR IMPLIED. FeelingTraveling DOES NOT MAKE OR GIVE ANY REPRESENTATION OR WARRANTY OR CONDITION OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING ANY WARRANTY OF MERCHANTABILITY, NON-INFRINGEMENT, QUALITY, OR FITNESS FOR A PARTICULAR PURPOSE. ALSO, FeelingTraveling MAY RESTRICT THE AVAILABILITY OF THE SITE IF THIS IS NECESSARY IN VIEW OF CAPACITY LIMITS, THE SECURITY OR INTEGRITY OF OUR SERVERS, OR TO CARRY OUT MAINTENANCE MEASURES FOR THE PROPER OR IMPROVED, MODIFIED OR ENHANCED FUNCTIONING OF THE SITE.<br/>
                <br/>
                <b>15. Limitation of Liability.</b>
                <br/>
                <br/>
                TO THE FULLEST EXTENT PERMITTED BY LAW, IN NO EVENT THE FeelingTraveling GROUP SHALL EITHER BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY LOST PROFITS, SPECIAL, EXEMPLARY, INDIRECT, INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES OR LIABILITIES WHETHER IN ACTION BASED IN CONTRACT, WARRANTY, STRICT LIABILITY, TORT OR OTHERWISE, EVEN IF SUCH PARTY HAS BEEN INFORMED IN ADVANCE OF THE POSSIBILITY OF SUCH DAMAGES OR IF SUCH DAMAGES COULD HAVE BEEN REASONABLY FORESEEN BY SUCH PARTY.<br/>
                IN NO EVENT SHALL THE FeelingTraveling GROUP’S LIABILITY TO YOU OR ANY OTHER PERSON ARISING OUT OF OR IN CONNECTION WITH THESE TERMS AND CONDITIONS OR THE SITE EXCEED, IN THE AGGREGATE, THE TOTAL AMOUNT PAID BY YOU FOR A PARTICULAR BOOKING WITH RESPECT TO WHICH SUCH LIABILITY RELATES, PLUS A 25%, OR IN THE CASE OF ANY LIABILITY NOT RELATED TO A PARTICULAR BOOKING THE TOTAL AMOUNT PAID BY YOU IN THE 12 MONTH PERIOD PRIOR TO THE EVENT GIVING RISE TO THE LIABILITY, PLUS A 25%, OR FIVE HUNDRED U.S. DOLLARS (US$500), IF NO SUCH PAYMENTS HAVE BEEN MADE, AS APPLICABLE. THE LIMITATIONS IN THIS SECTION SHALL SURVIVE AND APPLY EVEN IF ANY LIMITED REMEDY IN THESE TERMS AND CONDITIONS IS FOUND TO HAVE FAILED ITS ESSENTIAL PURPOSE.<br/>
                <br/>
                <b>16. Your Liability and Indemnification.</b>
                <br/>
                <br/>
                You agree to defend, indemnify, and hold harmless the FeelingTraveling Group from all liabilities, claims, losses and expenses, including attorney’s fees, that may arise, directly or indirectly from, as a result of or in connection with (i) your use of the Site and/or your participation in a Tour, including any damages, whether to persons or property, or death to any person, (ii) your Reviews, and (iii) any violation by You of these Terms and Conditions, including any infringement of any rights of third parties. The FeelingTraveling Group reserves the right, at its own expense, to assume the exclusive defense and control of any claim subject to indemnification by You, in which case You agree to cooperate with the FeelingTraveling Group in asserting any available defenses.<br/>
                <br/>
                <b>17. Release for the Guides’ Services.</b>
                <br/>
                <br/>
                IN ORDER TO BOOK AND PARTICIPATE IN A TOUR, THE GUIDES REQUIRE THAT YOU AGREE TO THE FOLLOWING IN RESPECT TO THEIR SERVICES.<br/>
                TO THE FULLEST EXTENT PERMITTED BY LAW, YOU RELEASE THE GUIDES FROM ANY LIABILITIES, CLAIMS, DAMAGES OF EVERY KIND AND NATURE, EVEN IF SUCH PARTY HAS BEEN INFORMED IN ADVANCE OF THE POSSIBILITY OF SUCH DAMAGES OR IF SUCH DAMAGES COULD HAVE BEEN REASONABLY FORESEEN, THAT MAY ARISE OUT OF, OR IN CONNECTION WITH, THE PARTICIPATION IN A TOUR.<br/>
                YOU AGREE TO DEFEND, INDEMNIFY, AND HOLD THE GUIDE HARMLESS FROM ANY LIABILITY, LOSS OR COST THE GUIDE MAY INCUR ARISING FROM ANY CLAIM YOU, OR A PARTICIPANT IN A TOUR BOOKED BY YOU, MAKE, DESPITE OF THE RELEASE INCLUDED IN THIS SECTION.<br/>
                <br/>
                <b>18. Intellectual Property.</b>
                <br/>
                <br/>
                The Site is protected by copyright, trademark, and/or other laws. All trademarks and logos used on or in connection with the Site are trademarks or registered trademarks of FeelingTraveling.<br/>
                You acknowledge and agree that the Site and all its components, texts, graphics, data, designs, look and feel, photographs, sounds, images, software, videos, and other content, including all associated intellectual property rights (“Proprietary Material”), are the exclusive property of FeelingTraveling and/or its licensors or authorizing third-parties, and is protected in all forms, media and technologies. You may not copy, download, use, edit or retransmit any Proprietary Material, in whole or in part, without the prior written permission of FeelingTraveling.<br/>
                <br/>
                <b>19. Dispute Resolution.</b>
                <br/>
                <br/>
                PLEASE READ THIS SECTION CAREFULLY. YOU WILL HAVE TO RESOLVE ANY DISPUTE THROUGH ARBITRATION AND NOT THROUGH A COURT PROCEEDING.<br/>
                Unless prohibited by law, all disputes arising out of, or in connection with these Terms and Conditions will be commenced, referred to and resolved by arbitration, except that You may assert a claim on an individual basis in small claims court. If any part of this arbitration agreement is deemed invalid or unenforceable, the other parts will remain in force. You and FeelingTraveling acknowledge and agree that they waive any constitutional and statutory rights to a trial by jury and to seek relief in a court of law.<br/>
                The arbitration will be conducted in English, before a single arbitrator and within the Province of Br/itish Columbia, Canada. The arbitration may be held remotely and costs will be borne by each party.<br/>
                However, You and FeelingTraveling agree to resolve any and all disputes in the least onerous manner. Therefore, You and FeelingTraveling agree to negotiate, informally and in good faith, a resolution to the dispute, before commencing arbitration. The claimant party will give notice to the other party of the initiation of the informal resolution process. You and FeelingTraveling will participate in a video conference within 45 days from the receipt of notice, unless extended by agreement of the parties. Any applicable limitation period will be temporarily suspended during this negotiation period.<br/>
                You may opt out of this arbitration agreement by notifying FeelingTraveling in writing and within 30 days from the effective date of this arbitration agreement. Your notice will include your name, the email account associated with your FeelingTraveling account and a clear statement that You are opting out of this arbitration agreement. If You opt out, all other parts of this Agreement will continue to apply.<br/>
                If the entire arbitration agreement is deemed invalid or unenforceable in respect to a particular claim or dispute, You agree to submit that claim or dispute not subject to arbitration to the exclusive jurisdiction of the courts of Br/itish Columbia, Canada.<br/>
                <br/>
                <b>20. Litigation Class Action Waiver</b>
                <br/>
                <br/>
                To the extent allowed by applicable law, You agree that any arbitration and proceeding to litigate in court any dispute arising out of or relating to this Agreement, will be conducted solely on an individual basis, and You agree not to seek to have any controversy, claim or dispute heard as a class action or any other type of representative proceeding.<br/>
                <br/>
                <b>21. Force Majeure.</b>
                <br/>
                <br/>
                We shall be relieved from liability for partial or complete failure to fulfill obligations under these Terms and Conditions due to force majeure circumstances. In case of force majeure, We shall notify You on the commencement and cessation of circumstances preventing Us from fulfilling our obligations.<br/>
                <br/>
                <b>22. Amendments to the Terms.</b>
                <br/>
                <br/>
                FeelingTraveling may modify these Terms and Conditions from time to time in its sole discretion. If we make changes to these Terms and Conditions, we will post the updated Terms and Conditions on the Site. If You continue accessing the Site and participating in a Tour, then You are deemed to have accepted the amended Terms and Conditions. If You do not agree to the amended Terms and Conditions, You will terminate these Terms and Conditions before the date they become effective, and will stop accessing the Site and will not participate in any Tour.<br/>
                <br/>
                <b>23. Term and Termination.</b>
                <br/>
                <br/>
                These Terms and Conditions will become effective immediately upon your use of the Site and/or your participation in a Tour and shall remain effective unless terminated by either party as provided hereunder.<br/>
                You may terminate these Terms and Conditions at any time by sending us an email, which shall be effective immediately upon delivery of such notice. If You terminate these Terms and Conditions, your account will be automatically canceled, all bookings from You will be canceled and any refund and/or payment to the Guide will be made in accordance with the Cancellation Policy.<br/>
                FeelingTraveling may terminate these Terms and Conditions for convenience at any time by sending You an email to your registered email address, which shall be effective immediately upon delivery of such notice. If We terminate these Terms and Conditions, We may cancel any of your bookings and refund You the amounts previously paid for those bookings. You will not be entitled to any compensation for termination and/or canceled bookings.<br/>
                FeelingTraveling may, at its sole discretion and without notice, terminate these Terms and Conditions at any time, and/or cancel or limit your access to your account if You have materially br/eached any obligation under these Terms and Conditions.<br/>
                When these Terms and Conditions have been terminated, You are not entitled to a restoration of your account and, if terminated by FeelingTraveling, You shall not apply for a new account or access and use the Site through another account.<br/>
                In the event of termination or expiration, the following sections shall survive: Acceptance of Risk and Waiver of Liability, Disclaimer, Limitation of Liability; Your Responsibility and Indemnification, Term and Termination; and General Provisions.<br/>
                <br/>
                <b>24. General Provisions.</b>
                <br/>
                <br/>
                (a) Waiver. Failure by FeelingTraveling to enforce any provision of these Terms and Conditions shall not be construed as a waiver of any provision or right.<br/>
                (b) Governing Law. The Site is operated by FeelingTraveling Canada Inc., a Canadian corporation. These Terms and Conditions, and all other aspects of the use of the Site, shall be governed by and construed in accordance with the laws of the Province of Br/itish Columbia, Canada.<br/>
                (c) Entire Agreement. Except for any additional terms included in the Site - excluding the Tour Terms uploaded by the Guides -, these Terms and Conditions constitute the entire agreement between You and FeelingTraveling with respect to the subject matter of these Terms and Conditions.<br/>
                (d) Severability. If any provision of these Terms and Conditions is found to be invalid or unenforceable, the remaining provisions shall remain valid and enforceable to the fullest extent possible and shall remain in full force and effect.<br/>
                (e) Successors. These Terms and Conditions shall be binding on You and FeelingTraveling, and their successors and assigns.<br/>
                (f) Notice. Any notice or other communication to be given hereunder will be in writing or electronic mail to any of the following addresses: to the Company: 1185 West Georgia Street, Suite 1200, V6E 4E6 Vancouver, Br/itish Columbia, Canada and support@FeelingTraveling.com; and to You: to the address and email listed in your Traveler’s Account.
            </p>
        </main>
    )
}

export default TermsAndConditions;