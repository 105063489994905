import { Link } from 'react-router-dom';
import './Footer.sass';

const Footer = () => {
    return (
        <footer className='footer'>
            <div className="footer__wrapper container">               
                <div className="footer__links">
                    <Link to='/blog' onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>Blog</Link>
                    <Link to='/guides' onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>Guides</Link>
                    <Link to="/terms-and-conditions" onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>Terms and conditions</Link>
                    <Link to="/privacy-policy" onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>Privacy policy</Link>
                </div>
                <span>© 2023. All Rights Reserved.</span>
            </div>
        </footer>
    )
}

export default Footer;