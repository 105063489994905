import { useEffect, useState } from 'react';
import './Search.sass';
import "react-datepicker/dist/react-datepicker.css";

interface SearchType {
    isBlog: boolean;
    isReversed: any;
    setType: any;
    setCountry: any;
    setState: any;
}

const Search = ({isBlog, isReversed, setType, setCountry, setState}: SearchType) => {

    const [countries, setCounries] = useState([]);
    const [currentCountry, setCurrentCountry] = useState<string>('');
    const [reversed, setReversed] = useState<boolean>(false);

    useEffect(() => {
        const fetchData = async () => {
            fetch('https://countriesnow.space/api/v0.1/countries/states')
                .then((res) => res.json())
                .then((res) => setCounries(res.data));
        }
        fetchData();
    }, [])

    const countriesNames:any = [];
    countries.forEach((element: any) => {
        countriesNames.push(element.name);
    })

    const getStateForCountry = (country: any):Array<string> => {
        const arr:any = [];
        countries.forEach((element: any) => {           
            if (element.name === country) {
                element.states.forEach((element: any) => {
                    arr.push(element.name);
                });
                return arr;
            }
        })
        return arr;
    }

    const changeReversed = (bool: boolean) => {
        setReversed(!bool);
        isReversed(!bool);
    }
    
    let search = `search ${!isBlog && 'search__guides-mobile'}`;

    return (
        <div className={search}>
            {isBlog && (
                <div className='search__by-type'>
                    <select name="type" id="type" onChange={(event: any) => setType(event.target.value)}>
                        <option value="">Search by type</option>
                        <option value="Tour">Tour</option>
                        <option value="Country">Country</option>
                    </select>
                </div>
            )}          
            <div className="search__by-country">
                <select name="country" id="country" onChange={e => {setCurrentCountry(e.target.value); setCountry(e.target.value)}}>
                    <option value="">Search by country</option>
                    {countriesNames.map((element: string, index: number) => (                 
                        <option key={index} value={element}>{element}</option>
                    ))}
                </select>
            </div>
                {!isBlog ? (
                    <div className="search__by-state">
                        <select name="state" id="state" onChange={e => setState(e.target.value)}>
                            <option value="">Search by state</option>
                            {getStateForCountry(currentCountry).map((element: string, index: number) => (                 
                                <option key={index} value={element}>{element}</option>
                            ))}
                        </select>
                    </div>
                ) : (
                    <div onClick={() => changeReversed(reversed)} className="search__by-date">
                        {reversed ? (
                            <div>Oldest</div>
                            ) : (  
                            <div>Newest</div>
                        )}
                    </div>
                )}               
        </div>
    )
}
export default Search;