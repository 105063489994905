import { makeAutoObservable } from "mobx";
import { IUser } from "../models/IUser";
import AuthService from "../services/AuthService";
import axios from 'axios';
import { AuthResponse } from "../models/response/AuthResponse";
import { API_URL } from "../http";
import AddService from "../services/AddService";

export default class Store {
    user = {} as IUser;
    isAuth = false;

    constructor() {
        makeAutoObservable(this);
    }

    setAuth(bool: boolean) {
        this.isAuth = bool;
    }

    setUser(user: IUser) {
        this.user = user;
    }

    async login(email: string, password: string) {
        try {
            const response = await AuthService.login(email, password);  
            localStorage.setItem('token', response.data.accessToken);
            localStorage.setItem('type', response.data.user.type);
            this.setAuth(true);
            this.setUser(response.data.user);            
        } catch (error) {
            console.log(error); 
        }
    }

    async registration(name: string, email: string, password: string) {
        try {
            const response = await AuthService.registration(name, email, password);
            localStorage.setItem('token', response.data.accessToken);
            localStorage.setItem('type', response.data.user.type);
            this.setAuth(true);
            this.setUser(response.data.user);
        } catch (error) {
            console.log(error);
        }
    }

    async checkAuth() {
        try {
            const response = await axios.get<AuthResponse>(`${API_URL}/refresh`, {withCredentials: true});         
            localStorage.setItem('token', response.data.accessToken);
            this.setAuth(true);
            this.setUser(response.data.user);
        } catch (error) {
            console.log(error);
        }
    }

    async loginAsGuide(email: string, password: string) {
        try {
            const response = await AuthService.loginAsGuide(email, password);  
            localStorage.setItem('token', response.data.accessToken);
            localStorage.setItem('type', response.data.user.type);
            this.setAuth(true);
            this.setUser(response.data.user);
        } catch (error) {
            console.log(error); 
        }
    }

    async registrationAsGuide(name: string, email: string, password: string) {
        try {
            const response = await AuthService.registrationAsGuide(name, email, password);
            localStorage.setItem('token', response.data.accessToken);
            localStorage.setItem('type', response.data.user.type);
            this.setAuth(true);
            this.setUser(response.data.user);
        } catch (error) {
            console.log(error);
        }
    }

    async checkAuthForGuide() {
        try {
            const response = await axios.get<AuthResponse>(`${API_URL}/refresh-as-guide`, {withCredentials: true});         
            localStorage.setItem('token', response.data.accessToken);
            this.setAuth(true);
            this.setUser(response.data.user);
        } catch (error) {
            console.log(error);
        }
    }

    async loginAsAdmin(email: string, password: string) {
        try {
            const response = await AuthService.loginAsAdmin(email, password);  
            localStorage.setItem('token', response.data.accessToken);
            localStorage.setItem('type', response.data.user.type);
            this.setAuth(true);
            this.setUser(response.data.user);
        } catch (error) {
            console.log(error); 
        }
    }

    async registrationAsAdmin(email: string, password: string) {
        try {
            const response = await AuthService.registrationAsAdmin(email, password);
            localStorage.setItem('token', response.data.accessToken);
            localStorage.setItem('type', response.data.user.type);
            this.setAuth(true);
            this.setUser(response.data.user);
        } catch (error) {
            console.log(error);
        }
    }

    async checkAuthForAdmin() {
        try {
            const response = await axios.get<AuthResponse>(`${API_URL}/refresh-as-admin`, {withCredentials: true});         
            localStorage.setItem('token', response.data.accessToken);
            this.setAuth(true);
            this.setUser(response.data.user);
        } catch (error) {
            console.log(error);
        }
    }

    async logout() {
        try {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const response = await AuthService.logout();
            localStorage.removeItem('token');
            this.setAuth(false);
            this.setUser({} as IUser);
        } catch (error) {
            console.log(error); 
        }
    }

    async editGuide(firsName: string, lastName: string, languages: string, text: string, country: string, state: string, city: string) {
        try {
            await AddService.editGuide(firsName, lastName, languages, text, country, state, city);          
        } catch (error) {
            console.log(error);
        }
    }

    async getAllGuides() {
        try {
            const response = await AddService.getAllGuides();
            return response.data;
        } catch (error) {
            console.log(error);
        }
    }

    async addGuideImage(formData: FormData) {
        try {
            const response = await AddService.addGuideImage(formData);
            return response.data;
        } catch (error) {
            console.log(error);
        }
    }

    async addBlog(formData: FormData) {
        try {
            await AddService.addBlog(formData);
        } catch (error) {
            console.log(error);
        }
    }

    async editBlog(id: string, country: string, type: string, img: string, title: string, subtitle: string, text: string) {
        try {
            await AddService.editBlog(id, type, country, img, title, subtitle, text);
        } catch (error) {
            console.log(error);
        }
    }

    async deleteBlog(id: string) {
        try {
            await AddService.deleteBlog(id);
        } catch (error) {
            console.log(error);
        }
    }

    async getAllBlog() {
        try {
            const response = await AddService.getAllBlog();
            return response.data;
        } catch (error) {
            console.log(error);
        }
    }

    async getOneBlogById(id: string) {
        try {
            const response = await AddService.getOneBlog(id);
            return response.data;
        } catch (error) {
            console.log(error);
        }
    }
}