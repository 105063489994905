import { useContext, useState } from 'react';
import './Login.sass';
import { Context } from '../..';
import { Link } from 'react-router-dom';

interface loginProps {
    type: string
}

const Login = ({type}: loginProps) => {

    const [name, setName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const {store} = useContext(Context);

    const toggleAuthorization = () => {
        const signUp = document.querySelector('.sign-up-container');
        signUp?.classList.toggle('sign-up-container__active');
    }

    return (
        <main className='login'>
            {type === 'user' ? (
                <div className="login__title">Login as traveler</div>
            ) : type === 'guide' ? (
                <div className="login__title">Login as guide</div>
            ) : (
                <div className="login__title">Login as admin</div>
            )}  

            <div className="login__wrapper">
                {type !== 'admin' && 
                <div className="form-container sign-up-container">
                    <form  action="#">
                        <h1>Create Account</h1>
                        {type !== 'admin' && <input 
                            onChange={e => setName(e.target.value)}
                            value={name}
                            type="text" 
                            placeholder="Name" 
                        />}
                        <input 
                            onChange={e => setEmail(e.target.value)}
                            value={email}
                            type="email" 
                            placeholder='Email'
                        />
                        <input 
                            onChange={e => setPassword(e.target.value)}
                            value={password}
                            type="password" 
                            placeholder='Password'
                        />
                        {type === 'user' ? (
                            <Link to="/">
                                <button onClick={() => {store.registration(name, email, password)}}>Sign Up</button>
                            </Link>
                        ) : type === 'guide' ? (
                            <Link to="/">
                                <button onClick={() => {store.registrationAsGuide(name, email, password)}}>Sign Up</button>
                            </Link>
                        ) : (
                            <Link to="/">
                                <button onClick={() => {store.registrationAsAdmin(email, password)}}>Sign Up</button>
                            </Link>
                        )}                     
                        <span onClick={toggleAuthorization}>I have an account</span>
                    </form>
                </div>}       
                <div className="form-container sign-in-container">
                    <form action="#">
                        <h1>Sign in</h1>
                        <input 
                            onChange={e => setEmail(e.target.value)}
                            value={email}
                            type="email" 
                            placeholder='Email'
                        />
                        <input 
                            onChange={e => setPassword(e.target.value)}
                            value={password}
                            type="password" 
                            placeholder='Password'
                        />
                        <a href="/">Forgot your password?</a>                      
                        {type === 'user' ? (
                            <Link to="/">
                                <button onClick={() => {store.login(email, password)}}>Sign In</button>
                            </Link>
                        ) : type === 'guide' ? (
                            <Link to="/">
                                <button onClick={() => {store.loginAsGuide(email, password)}}>Sign In</button>
                            </Link>
                        ) : (
                            <Link to="/admin-panel">
                                <button onClick={() => {store.loginAsAdmin(email, password)}}>Sign In</button>
                            </Link>
                        )}  
                        {type !== 'admin' && <span onClick={toggleAuthorization}>I don't have an account</span>}
                    </form>
                </div>
                {type !== 'admin' && 
                <div className="overlay-container">
                    <div className="overlay">
                        <div className="overlay-panel overlay-left">
                            <h1>Welcome Back!</h1>
                            <p>To keep connected with us please login with your personal info</p>
                            <button className="ghost" id="signIn" onClick={() => {
                                document.querySelector('.login__wrapper')?.classList.remove("right-panel-active");
                            }}>Sign In</button>
                        </div>
                        <div className="overlay-panel overlay-right">
                            <h1>Hello, Friend!</h1>
                            <p>Enter your personal details and start journey with us</p>
                            <button className="ghost" id="signUp" onClick={() => {
                                document.querySelector('.login__wrapper')?.classList.add("right-panel-active");
                            }}>Sign Up</button>
                        </div>
                    </div>
                </div>}
            </div>

        </main>
    )
}

export default Login;